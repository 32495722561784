:root {
  --top-menu-color: #61605D;
}

@font-face {
    font-family: 'Golos';
    src: local('Golos'),
      url('./../fonts/golos/400/font.ttf') format('truetype');
    font-weight: bold;
}

body {
    font-family: "Golos" !important;
}
.font-golos {
    font-family: "Golos";
    font-size: 1.2rem;
    text-align: center;
    font-weight: bold;  
} 
.font-golos-white {
    font-family: "Golos";
    font-size: 0.8rem;
    text-align: left;
    color:#607C8B;
    margin-left: 10px;
} 
.login-background {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url('./../images/background.jpg') !important;
}
.login-container {
    background-color:white;
}

.header-background
{
    background-color:white;
}

.grid-container {
    display: grid;
    grid-template-rows: 60px auto 20px;
    grid-template-columns: 1fr;
    height: 100vh;
  }
  
  .grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
  }
  /*
  .top {
  }
  
  .middle {
  }
  */
  
  .bottom {
    background-color: #ADD8E6;
  }
  
  .container {
    display: grid;
    grid-template-columns: 360px 1fr 200px;
    grid-gap: 10px;
    color:black;
    align-items: center;
  }
  
  .left-column {
    /* стили для левой колонки */
    width: 360px;
    margin-left: 50px;
  }
  /*
  .middle-column {
    
  }
  */
  
  .right-column {
    /* стили для правой колонки */
    width: 200px;
    text-align: right;
  }

  /* Родительские стили для иконок и надписей в главном меню */
  .top-menu {
    display: flex;
    align-items: center;
  }  
  /* Стили для иконок в главном меню */
  .top-menu-icon {
    color:var(--top-menu-color);
    margin-left: 3px;
    margin-right: 3px;
  }  
  /* Стили для иконок в главном меню */
  .top-menu-button {
    font-size: 18;
    color:var(--top-menu-color);
    display: inline-block;
  }  
  /* Стили для расстояния между надписями в главном меню*/
  .top-menu-box {
    margin-left: 40px;
    margin-right: 40px;    
  }    
/* Стили для левого меню*/
.left-menu-box {

  width: 360px;
  top: 62px;
  position: fixed;
  background-color: white;
  height: 100%;
  transition: transform 0.3s ease-out;
}    

.container-map {
  height:100%;
  width:100%;
}

.container2 {
  display: grid;
  grid-template-columns: 1fr 200px;
  grid-gap: 10px;
  color:black; 
  align-items: center;
}
/*
.right-column2 {
  
}
*/
  /* Родительские стили для иконок и надписей в главном меню */
  .top-menu2 {
    display: flex;
    align-items: center;

  }  
  /* Стили для иконок в главном меню */
  .top-menu-icon2 {
    color:var(--top-menu-color);
    margin-left: 3px;
    margin-right: 3px;
    font-size:'small';
  }  
  /* Стили для иконок в главном меню */
  /*
  .top-menu-button2 {
    font-size: 18;
    color:var(--top-menu-color);
    display: inline-block;
    
  } 
  */ 
  .top-menu-text2 {
    color:var(--top-menu-color);
    text-transform: none;
    font-size: 0.8rem;

    display: inline-block;
    min-height: unset;
    
  }  
  /* Стили для расстояния между надписями в главном меню*/
  .top-menu-box2 {
    margin-left: 40px;
    margin-right: 40px;    
  }    


    /* Родительские стили мини-фильтрации */
    .mini-filtration {
      display: grid;
      height:37vh; 
    }  

    .mini-filtration-item {
      min-width: 0;
      flex: '1 1 100%';
    }

    .mini-filtration-chart {
      flex-grow: 1;
      overflow: 'hidden';
    }
  